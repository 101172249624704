import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import {AuthService, Role} from "./auth.service";
import {AppConfig} from "../../content/util/config/app-config";

@Injectable({providedIn: 'root'})
export class AuthGuard  {

  role: Role;

  constructor(protected authService: AuthService, protected config: AppConfig, protected router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;

    // dont redirect on the server
    if (this.config.isServer)
      return true;

    // if we require a role, check for role
    if (this.role && this.authService.hasRole(this.role))
      return true;

    // otherwise, just require to be logged in
    if (!this.role && this.authService.authenticated())
      return true;

    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;

    // Navigate to the login page
    this.router.navigate(['/user/login']);
    return false;
  }

}
